import m from 'mithril'
import Layout from '../views/layout/layout'

const routeResolver = (component, app) => ({
  onmatch: (args, requestedPath) => {
    args.app = {
      ...app,
      currentRoute: requestedPath,
      section: requestedPath.split('/')[1].split('?')[0] || ''
    }
    if (requestedPath === '/') return m.route.set('/games')
    return new Promise(resolve => component.then(module => resolve(module.default)))
  },
  render: vnode => m(Layout, { ...vnode.attrs }, vnode)
})

export default routeResolver
