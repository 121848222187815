import store from '../lib/store'

const auth = () => {
  store.removeExpiredKeys()

  let token = store.get('calcetthon.authToken')

  const isAuth = () => !!token

  const setToken = newToken => {
    token = newToken
    store.set('calcetthon.authToken', newToken, store.setExpireTime())
  }

  const clear = () => {
    token = null
    store.remove('calcetthon.authToken')
  }

  return {
    isAuth,
    setToken,
    clear
  }
}

export default auth
