// Components
import Dashboard from './views/dashboard/dashboard'
const EditGame = import('./views/games/edit-game')
const EditGameScore = import('./views/games/edit-game-score')
const Game = import('./views/games/game')
const Games = import('./views/games/games')
const GamesSkunks = import('./views/games/games-skunks')
const Login = import('./views/user/login')
const NewGame = import('./views/games/new-game')
const NewGameScore = import('./views/games/new-game-score')
const Player = import('./views/players/player')
const Players = import('./views/players/players')
const Playground = import('./views/playground/playground')
const Profile = import('./views/user/profile')
const NewPlayer = import('./views/players/new-player')
const PasswordForgot = import('./views/user/password-forgot')
const PasswordReset = import('./views/user/password-reset')
const Team = import('./views/teams/team')
const Teams = import('./views/teams/teams')

const routes = {
  '/': Dashboard,
  '/games': Games,
  '/games/:key': Game,
  '/games/:key/edit': EditGame,
  '/games/:key/edit/score': EditGameScore,
  '/games-skunks': GamesSkunks,
  '/login': Login,
  '/new-game': NewGame,
  '/new-game/score': NewGameScore,
  '/new-player': NewPlayer,
  '/players': Players,
  '/players/:key': Player,
  '/playground': Playground,
  '/profile': Profile,
  '/password-forgot': PasswordForgot,
  '/password-reset': PasswordReset,
  '/teams': Teams,
  '/teams/:key': Team
}

export default routes
