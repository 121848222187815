import { env } from '../config'
import translatejs from 'translate.js'
// i18n translations
import enUS from '../i18n/en_US.json'

const translations = {
  enUS
}

const options = {
  debug: env.isDevelop
}

let t = translatejs(translations.enUS, options)

const translate = (key, args) => t(key, args)

translate.init = (lang) => {
  t = translatejs(translations[lang] || translations.enUS, options)
}

translate.arr = (key, args) => t.arr(key, args)

export default translate
