import stream from 'mithril/stream'

const notifications = stream([])

const current = () => notifications()[0]

const popup = notification => {
  notifications([
    ...notifications(),
    {
      type: 'info', // 'info', 'success', 'danger', 'warning'
      message: '...',
      action: null,
      permanent: false,
      id: Date.now(),
      ...notification
    }
  ])
}

const close = (id) => {
  notifications(notifications().filter(x => x.id !== id))
}

// DEBUG test notification bombing
// const test = () => setInterval(() => {
//   const types = ['info', 'success', 'danger', 'warning']
//   popup({
//     type: types[Math.floor(Math.random() * types.length)],
//     message: `Prova prova prova ${Date.now()}`,
//     permanent: Math.random() >= 0.5
//   })
// }, 1000)
// test()

export {
  notifications,
  current,
  popup,
  close
}
