import m from 'mithril'
import { env } from './config'
import store from './lib/store'
import { popup } from './lib/popup-notifications'

const request = opts => {
  const options = {
    ...opts,
    extract: xhr => {
      if (!xhr.status) throw new Error('Request has failed')
      const isJSON = xhr.getResponseHeader('Content-Type').indexOf('application/json') !== -1
      if (xhr.status >= 400) {
        return Promise.reject(xhr)
      } else {
        return isJSON && xhr.responseText ? JSON.parse(xhr.responseText) : xhr.responseText
      }
    },
    headers: {}
  }

  // Authentication token
  const authToken = store.get('calcetthon.authToken') // TODO temp!!!
  if (authToken) options.headers.Authorization = `Bearer ${authToken}`

  return m.request(options)
    .catch(error => {
      const message = error.response ? JSON.parse(error.response).message : error.message
      // Log errors to console in development
      if (env.isDevelop) {
        console.error(`[API request error ${error.status} - ${error.statusText}]`)
        console.log(options)
      }
      if (!opts.skipErrorNotifications) {
        popup({
          type: 'danger',
          message: message
        })
      }
      return Promise.reject(error)
    })
}

const buildRequest = (method, url, body, args) => request({
  method,
  url,
  ...body && { body },
  ...(args && typeof args === 'object') && { ...args }
})

export default {
  get: (url, args) => buildRequest('GET', url, null, args),

  post: (url, body, args) => buildRequest('POST', url, body, args),

  put: (url, body, args) => buildRequest('PUT', url, body, args)
}
