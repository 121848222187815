import m from 'mithril'

const Dropdown = () => {
  let open = false

  const clickHandler = () => {
    open = !open
    m.redraw()
    if (!open) document.removeEventListener('click', clickHandler)
  }

  function view ({ attrs, children }) {
    return m('.dropdown', {
      className: [
        open ? 'is-active' : '',
        attrs.classes
      ].join(' ').trim()
    }, [
      m('.dropdown-trigger', [
        m('button', {
          'aria-controls': attrs.id,
          'aria-haspopup': true,
          className: attrs.buttonClasses || 'button nobtn',
          disabled: attrs.disabled,
          onclick: (e) => {
            e.preventDefault()
            document.addEventListener('click', clickHandler)
          },
          title: attrs.title
        }, attrs.buttonContent)
      ]),
      m('.dropdown-menu', {
        id: attrs.id,
        role: 'menu'
      }, m('.dropdown-content', children))
    ])
  }

  return { view }
}

export default Dropdown
