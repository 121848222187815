import m from 'mithril'
import Icon from '../../common/icon/icon'
import t from '../../../lib/translate'

const playerLinkAttrs = (id, isCappotto, classes) => ({
  className: [
    'games-table-player',
    classes,
    isCappotto ? 'has-text-weight-semibold has-text-cobalt' : 'has-text-weight-semibold'
  ].filter(x => x).join(' '),
  href: `/players/${id}`
})

const gameScore = game => m('.games-table-score.is-size-5.has-text-weight-semibold.rel', {
  className: game.cappotto ? 'has-text-warning' : ''
}, [
  game.redTeam.score,
  ' - ',
  game.blueTeam.score,
  // Winner dot
  m('.games-table-winner', {
    className: game.redTeam.score === 6
      ? 'games-table-winner-left has-background-danger'
      : 'games-table-winner-right has-background-info'
  })
])

const GamesTable = () => {
  function view ({ attrs }) {
    const viewport = attrs.app.state.viewport()
    const isMobile = attrs.forceMobile || viewport.size === 'narrow' || viewport.size === 'mobile'
    return m('table.table.is-borderless.narrow-container.vamid', [
      m('thead', [
        m('tr', isMobile ? [
          // MOBILE TABLE HEAD
          // Red team icons
          m('th.has-text-right.is-size-4', [
            m(Icon, { name: 'faMale', classes: ['mr1', 'has-text-danger'] }),
            m(Icon, { name: 'faMale', classes: ['has-text-danger'] })
          ]),
          m('th', ''),
          // Blue team icons
          m('th.is-size-4', [
            m(Icon, { name: 'faMale', classes: ['mr1', 'has-text-info'] }),
            m(Icon, { name: 'faMale', classes: ['has-text-info'] })
          ])
        ] : [
          // DESKTOP TABLE HEAD
          m('th', t('games.date')),
          m('th.has-text-right', t('games.defence')),
          m('th.has-text-right', m('span.mr4', t('games.attack'))),
          m('th.has-text-centered', [
            // Red team icons
            m('.is-size-5.is-inline-block.mr3', [
              m(Icon, { name: 'faMale', classes: ['mr1', 'has-text-danger'] }),
              m(Icon, { name: 'faMale', classes: ['has-text-danger'] })
            ]),
            // Blue team icons
            m('.is-size-5.is-inline-block', [
              m(Icon, { name: 'faMale', classes: ['mr1', 'has-text-info'] }),
              m(Icon, { name: 'faMale', classes: ['has-text-info'] })
            ])
          ]),
          m('th', m('span.ml4', t('games.attack'))),
          m('th.td-expand', t('games.defence'))
        ])
      ]),
      m('tbody', [
        attrs.games.map(game => m('tr', {
          className: game.cappotto ? 'games-table-cappotto-row' : ''
        }, isMobile ? [
          // MOBILE TABLE BODY
          m('td.has-text-right', [
            // Positions
            m('.games-table-positions.is-pulled-left', [
              'D',
              m('br'),
              'A'
            ]),
            // Red team players
            m(m.route.Link, playerLinkAttrs(game.redTeam.defence.id, game.redTeam.score === 0), game.redTeam.defence.nickname),
            m('br'),
            m(m.route.Link, playerLinkAttrs(game.redTeam.attack.id, game.redTeam.score === 0), game.redTeam.attack.nickname)
          ]),
          m('td.has-text-centered.nowrap', [
            // Date
            m(m.route.Link, {
              className: 'games-table-date is-size-8',
              href: `/games/${game.id}`,
              title: game.humanDate
            }, game.humanDateCompact),
            // Score
            gameScore(game)
          ]),
          m('td', [
            // Positions
            m('.games-table-positions.is-pulled-right', [
              'D',
              m('br'),
              'A'
            ]),
            // Blue team players
            m(m.route.Link, playerLinkAttrs(game.blueTeam.defence.id, game.blueTeam.score === 0), game.blueTeam.defence.nickname),
            m('br'),
            m(m.route.Link, playerLinkAttrs(game.blueTeam.attack.id, game.blueTeam.score === 0), game.blueTeam.attack.nickname)
          ])
        ] : [
          // DESKTOP TABLE BODY
          // Date
          m('td.td-shrink', m(m.route.Link, {
            className: 'games-table-date is-size-8 pr5',
            href: `/games/${game.id}`,
            title: game.humanDate
          }, game.humanDateCompact)),
          // Red team players
          m('td.td-shrink.has-text-right',
            m(m.route.Link, playerLinkAttrs(game.redTeam.defence.id, game.redTeam.score === 0), game.redTeam.defence.nickname)
          ),
          m('td.td-shrink.has-text-right',
            m(m.route.Link, playerLinkAttrs(game.redTeam.attack.id, game.redTeam.score === 0, 'ml2 mr4'), game.redTeam.attack.nickname)
          ),
          // Score
          m('td.td-shrink.has-text-centered', [
            gameScore(game)
          ]),
          // Blue team players
          m('td.td-shrink',
            m(m.route.Link, playerLinkAttrs(game.blueTeam.attack.id, game.blueTeam.score === 0, 'ml4 mr2'), game.blueTeam.attack.nickname)
          ),
          m('td.td-shrink',
            m(m.route.Link, playerLinkAttrs(game.blueTeam.defence.id, game.blueTeam.score === 0), game.blueTeam.defence.nickname)
          )
        ]))
      ])
    ])
  }

  return { view }
}

export default GamesTable
