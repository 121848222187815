import m from 'mithril'
import throttle from 'lodash.throttle'
import routes from './routes'
import initState from './state'
import initAuth from './models/auth'
import routeResolver from './lib/route-resolver'
import store from './lib/store'
import { getViewport } from './lib/viewport'
// Styles
import './scss/app.scss'

// Parcel live reload
if (module.hot) {
  module.hot.accept(() => {
    console.log('[Parcel] Reloading window...')
    window.location.reload(true)
  })
}

// Init state and user
const auth = initAuth()
const state = initState()

// Window resize listener for responsive behaviour
window.onresize = throttle(() => {
  state.viewport(getViewport())
  m.redraw()
}, 200)
state.viewport(getViewport())

// Init user data, games and players
state.user = store.get('calcetthon.user')

// Mount app routes
const appRoutes = {}
for (const key in routes) {
  appRoutes[key] = routeResolver(routes[key], { auth, state })
}

const appContainer = document.getElementById('app')
m.route(appContainer, '/', appRoutes)
