import m from 'mithril'
import stream from 'mithril/stream'
import { env } from '../../config'
import store from '../../lib/store'
import t from '../../lib/translate'
import Dropdown from '../common/dropdown/dropdown'
import PopupNotifications from '../common/popup-notifications/popup-notifications'
import ResponsiveSize from './responsive-size'
import Icon from '../common/icon/icon'
import logo from '../../img/logo.png'
import noAvatar from '../../img/no-avatar.png'

const linkAttrs = (section, current, isOpen) => ({
  className: section === current ? 'is-active' : '',
  href: `/${section}`,
  onclick () {
    isOpen(false)
  }
})

const Layout = ({ attrs }) => {
  const isOpen = stream(false)

  const logout = () => {
    store.remove('calcetthon.user')
    attrs.app.auth.clear()
    attrs.app.state.user = null
  }

  function view ({ attrs, children }) {
    // Exclude login and password forgot/reset routes from common layout
    if (attrs.app.section === 'login' || attrs.app.section === 'password-forgot' || attrs.app.section === 'password-reset') return children

    const user = attrs.app.state.user
    return [
      // SIDEBAR
      m('.sidebar', [
        isOpen() && m('.sidebar-overlay', {
          onclick () {
            isOpen(false)
          },
          oncreate ({ dom }) {
            window.requestAnimationFrame(() => { dom.classList.add('is-open') })
          },
          onbeforeremove ({ dom }) {
            dom.classList.remove('is-open')
            return new Promise(resolve => {
              setTimeout(resolve, 300)
            })
          }
        }),
        m('.sidebar-panel', {
          className: isOpen() ? 'is-open' : ''
        }, [
          m('.sidebar-topbar', [
            m(m.route.Link, {
              className: 'sidebar-logo',
              href: '/'
            }, [
              m('img.sidebar-logo-image', {
                alt: 'MV Labs',
                height: 28,
                style: { height: '28px' },
                src: logo
              }),
              'Calcetthon'
            ])
          ]),
          m('aside.menu.pa3', [
            m('p.menu-label', t('nav.games')),
            m('ul.menu-list', [
              m('li', m(m.route.Link, linkAttrs('games', attrs.app.section, isOpen), t('nav.all_games'))),
              m('li', m(m.route.Link, linkAttrs('games-skunks', attrs.app.section, isOpen), t('nav.skunks'))),
              attrs.app.auth.isAuth() && m('li', m(m.route.Link, linkAttrs('new-game', attrs.app.section, isOpen), t('nav.new_game')))
            ]),
            m('p.menu-label', t('nav.players')),
            m('ul.menu-list', [
              m('li', m(m.route.Link, linkAttrs('players', attrs.app.section, isOpen), t('nav.players')))
            ]),
            m('p.menu-label', t('nav.teams')),
            m('ul.menu-list', [
              m('li', m(m.route.Link, linkAttrs('teams', attrs.app.section, isOpen), t('nav.teams')))
            ])
          ])
        ])
      ]),
      // TOP BAR
      m('.topbar', [
        m('button.button.is-light.mt2.is-hidden-desktop.sidebar-toggle', {
          onclick () {
            isOpen(true)
          }
        }, m(Icon, { name: 'faBars' })),
        m('.mlauto.is-pulled-right', attrs.app.auth.isAuth() && user
          ? m(Dropdown, {
            id: 'logged-user-menu',
            classes: 'is-right',
            buttonClasses: 'nobtn',
            buttonContent: [
              m('span.has-text-white.has-text-weight-semibold.ml3', user.nickname),
              m(Icon, {
                name: 'faCaretDown',
                classes: ['has-text-white', 'pt1', 'ml1', 'mr2']
              }),
              m('img.topbar-user-image.vamid', {
                src: user.profilePicture || noAvatar
              })
            ]
          }, [
            m(m.route.Link, {
              className: 'dropdown-item',
              href: '/profile'
            }, t('nav.profile')),
            m(m.route.Link, {
              className: 'dropdown-item',
              href: '/new-player'
            }, t('topbar.register_new_player')),
            m('a.dropdown-item', {
              href: '#',
              onclick (e) {
                e.preventDefault()
                logout()
              }
            }, t('nav.logout'))
          ])
          : m(m.route.Link, {
            className: 'has-text-white',
            href: '/login'
          }, t('topbar.login'))
        )
      ]),
      // MAIN CONTAINER
      m('.main-container', [
        // MAIN CONTENT
        children
      ]),
      m(PopupNotifications, {
        app: attrs.app
      }),
      env.isDebug && m(ResponsiveSize, {
        app: attrs.app
      })
    ]
  }

  return { view }
}

export default Layout
