import engine from 'store/src/store-engine'
import localStorage from 'store/storages/localStorage'
import defaults from 'store/plugins/defaults'
import expire from 'store/plugins/expire'

const storages = [localStorage]
const plugins = [defaults, expire]
const store = engine.createStore(storages, plugins)

store.setExpireTime = (time = 1296000000) => new Date().getTime() + time // defaults to 15 days

export default store
