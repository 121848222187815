import { env } from './config'
import request from './request'

const APIBaseURL = env.APIBaseURL

const changeUserEmail = data => request.post(`${APIBaseURL}/user/email`, data)

const changeUserPassword = data => request.post(`${APIBaseURL}/user/password`, data)

const editGame = (gameId, data) => request.put(`${APIBaseURL}/games/${gameId}`, data)

const editUserInfo = data => request.post(`${APIBaseURL}/user/info`, data)

const getGame = gameId => request.get(`${APIBaseURL}/games/${gameId}`)

const getGames = params => request.get(`${APIBaseURL}/games`, { params })

const getPlayer = playerId => request.get(`${APIBaseURL}/players/${playerId}`)

const getPlayers = params => request.get(`${APIBaseURL}/players`, { params })

const getUserProfile = () => request.get(`${APIBaseURL}/user/profile`)

const logGame = data => request.post(`${APIBaseURL}/games`, data)

const login = data => request.post(`${APIBaseURL}/user/login`, data, { skipErrorNotifications: true })

const registerPlayer = data => request.post(`${APIBaseURL}/players`, data)

const forgotPassword = data => request.post(`${APIBaseURL}/user/password-forgot`, data)

const resetPassword = data => request.post(`${APIBaseURL}/user/password-reset`, data)

const getPlayersStats = () => request.get(`${APIBaseURL}/stats/players`)

const getPlayerStats = playerId => request.get(`${APIBaseURL}/stats/players/${playerId}`)

const getTeamsStats = () => request.get(`${APIBaseURL}/stats/teams`)

const getTeamStats = teamId => request.get(`${APIBaseURL}/stats/teams/${teamId}`)

const syncStats = () => request.post(`${APIBaseURL}/stats/sync`)

export {
  changeUserEmail,
  changeUserPassword,
  editGame,
  editUserInfo,
  getGame,
  getGames,
  getPlayer,
  getPlayers,
  getPlayersStats,
  getPlayerStats,
  getTeamsStats,
  getTeamStats,
  getUserProfile,
  logGame,
  login,
  registerPlayer,
  forgotPassword,
  resetPassword,
  syncStats
}
