// import m from 'mithril'
// import t from '../../lib/translate'

const Dashboard = () => {
  function view () {

  }

  return { view }
}

export default Dashboard
