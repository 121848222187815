import m from 'mithril'

const Form = ({ attrs }) => {
  // Check for required attrs
  if (!attrs.id) {
    throw new Error('Form: missing required "id"')
  }

  function view ({ attrs, children }) {
    return m('form', {
      id: attrs.id,
      className: attrs.className,
      onsubmit: e => {
        e.preventDefault()
        if (attrs.onSubmit && typeof attrs.onSubmit === 'function') attrs.onSubmit()
      },
      novalidate: true // Skip HTML5 validation
    }, children)
  }

  return { view }
}

export default Form
