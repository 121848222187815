import m from 'mithril'

const InputField = ({ attrs }) => {
  // Check for required attrs
  if (!attrs.field) {
    throw new Error('InputField: missing required "field"')
  }
  if (typeof attrs.field !== 'function') {
    throw new Error('InputField: "field" must be a function')
  }
  if (!attrs.id) {
    throw new Error('InputField: missing required "id"')
  }

  if (attrs.labelAsPlaceholder) {
    attrs.placeholder = attrs.label || attrs.placeholder
    attrs.label = false
  }

  function view ({ attrs }) {
    return [
      // Label (optional)
      attrs.label && m('label.label', { for: attrs.id }, attrs.label),
      // Input field
      m('input.input', {
        id: attrs.id,
        type: attrs.type || 'text', // optional
        value: attrs.field(),
        className: [
          attrs.className ? attrs.className : '',
          attrs.field.error() ? 'is-danger' : ''
        ].join(' ').trim(),
        oninput: e => attrs.field(e.target.value),
        onchange: e => {
          e.redraw = false
          // Prevent submit button jump
          const handler = () => {
            document.removeEventListener('click', handler)
            window.requestAnimationFrame(() => {
              attrs.field.isValid()
              m.redraw()
            })
          }
          document.addEventListener('click', handler)
        },
        disabled: attrs.disabled, // optional
        readonly: attrs.readonly, // optional
        placeholder: attrs.placeholder, // optional
        tabindex: attrs.tabindex, // optional
        autofocus: attrs.autofocus, // optional
        autocomplete: attrs.autocomplete, // optional
        onkeyup: attrs.onkeyup, // optional
        onfocus: attrs.onfocus, // optional
        onblur: attrs.onblur // optional
      }),
      // Display error message, if any
      attrs.field.error() && m('p.help.is-danger', attrs.field.error()),
      // Helper message (optional)
      attrs.helper && m('p.help.has-text-grey', attrs.helper)
    ]
  }

  return { view }
}

export default InputField
