const colors = {
  bodyColor: '#263544',
  brandPrimary: '#7ab317',
  brandPrimaryDark: '#4c6f0e',
  brandPrimaryLight: '#a3e532',
  brandAlt: '#b35017'
}

// Set these variables via environment variables in development,
// replace the placeholder strings at container startup in production
const DEBUG = process.env.DEBUG || '##PROD_DEBUG##'
const API_BASE_URL = process.env.API_BASE_URL || '##PROD_API_BASE_URL##'

const environment = process.env.NODE_ENV

const env = {
  current: environment,
  isDebug: DEBUG === 'enabled',
  isDevelop: environment === 'development',
  isStage: environment === 'stage',
  isProduction: environment === 'master',
  APIBaseURL: API_BASE_URL
}

const dateFormatStandard = 'yyyy-MM-dd'
const dateFormatHuman = 'eeee dd/MM/yy, HH:mm'
const dateFormatHumanCompact = 'dd/MM/yy'

export {
  colors,
  env,
  dateFormatStandard,
  dateFormatHuman,
  dateFormatHumanCompact
}
