import m from 'mithril'
import t from '../../../lib/translate'

const LogGameScore = ({ attrs }) => {
  const points = [0, 1, 2, 3, 4, 5, 6]
  const score = attrs.score || {
    redTeam: 0,
    blueTeam: 0
  }

  const getWinner = () => {
    if (score.redTeam === score.blueTeam || (score.redTeam !== 6 && score.blueTeam !== 6)) return
    return score.redTeam === 6 ? t('common.red') : t('common.blue')
  }

  const pointMarker = (point, team, size) => m('button.button.is-block', {
    className: [
      team === 'redTeam' ? 'is-danger' : 'is-info',
      score[team] === point
        ? size === 'medium' ? 'mb5' : 'mb4'
        : '',
      size === 'medium' ? 'is-large' : ''
    ].filter(x => x).join(' '),
    disabled: point === 7,
    onclick () {
      score[team] = point
    }
  }, point === 7 ? 'x' : point)

  const validScore = () => (score.redTeam === 6 || score.blueTeam === 6) && (score.redTeam !== score.blueTeam)

  function view ({ attrs }) {
    const viewport = attrs.app.state.viewport()
    const size = viewport.size === 'narrow' || viewport.size === 'mobile' ? 'normal' : 'medium'
    const winner = getWinner()
    const cappotto = score.redTeam + score.blueTeam === 6
    const isValidScore = validScore(score)
    return m('.new-game-score.narrow-container.has-text-centered.flex.flex-cols', [
      m('.new-game-score-points', points.map(point => pointMarker(point, 'redTeam', size))),
      m('.flex-grow.pa4', [
        m('.is-size-1.has-text-weight-bold.pt4', [
          m('span.has-text-danger', score.redTeam),
          ' - ',
          m('span.has-text-info', score.blueTeam)
        ]),
        m('table', [
          m('tr', [
            m('td.has-text-right.w50', attrs.teams.redTeam.defence.nickname),
            m('td.w50', attrs.teams.blueTeam.defence.nickname)
          ]),
          m('tr', [
            m('td.has-text-right.w50', attrs.teams.redTeam.attack.nickname),
            m('td.w50', attrs.teams.blueTeam.attack.nickname)
          ])
        ]),
        m('h3.has-text-centered.is-lowercase', winner
          ? [
            t('games.team_wins', { winner }),
            cappotto && ` ${t('common.cappotto')}!`
          ]
          : '...'),
        m('button.button.is-primary.is-large.w100.mt3', {
          className: attrs.isSubmitting ? 'is-loading' : '',
          disabled: !isValidScore,
          onclick () {
            attrs.isSubmitting = true
            attrs.submit(score)
          }
        }, t('games.log_game'))
      ]),
      m('.new-game-score-points', points.map(point => pointMarker(point, 'blueTeam', size)))
    ])
  }

  return {
    view
  }
}

export default LogGameScore
