const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const key = prefix.length ? `${prefix}${k.charAt(0).toUpperCase()}${k.slice(1)}` : k
    if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], key))
    else acc[key] = obj[k]
    return acc
  }, {})

export {
  flattenObject
}
