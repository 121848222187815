import m from 'mithril'
import Icon from '../icon/icon'
import t from '../../../lib/translate'

const Breadcrumb = {
  view: ({ attrs }) => {
    const segments = attrs.links.length
    return m('.breadcrumb-bar', [
      m('nav.breadcrumb.has-arrow-separator.ph3', {
        'aria-label': 'breadcrumbs'
      }, m('ul', [
        m('li', m(m.route.Link, {
          href: '/'
        }, [
          m(Icon, { name: 'faHome', classes: ['fa-w-16 mr1'] }),
          t('nav.dashboard')
        ])),
        attrs.links.map((link, index) => index + 1 === segments
          ? m('li.is-active', m('a', {
            'aria-current': 'page',
            href: '#'
          }, link.text))
          : m('li', m(m.route.Link, {
            href: link.href
          }, link.text))
        )
      ]))
    ])
  }
}

export default Breadcrumb
