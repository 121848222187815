// Forked from powerform@2.8.0
// https://github.com/ludbek/powerform

const SkipValidation = function (message) {
  this.name = 'SkipValidation'
  this.message = message
}

const validateSingle = (data, validators, multipleErrors, all, key) => {
  let errors = []

  if (typeof validators === 'function') {
    validators = [validators]
  }

  for (let i = 0; i < validators.length; i++) {
    try {
      let error = validators[i](data, all)
      if (typeof error === 'string') {
        errors.push(error.replace('{value}', data).replace('{key}', key))
      }
    } catch (err) {
      if (err instanceof SkipValidation) {
        break
      }
    }
  }

  if (multipleErrors === true) return errors

  if (errors.length > 0) return errors[0]
}

const isFunction = data => typeof data === 'function'

const isArray = data => data instanceof Array

let isValidValidator = validator => isFunction(validator) || isArray(validator)

let clone = data => !data ? data : JSON.parse(JSON.stringify(data))

let isequal = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)

function prop (model, field, defaultValue = null, multipleErrors, projector, debounce = 0) {
  defaultValue = typeof (defaultValue) === 'undefined' ? null : defaultValue
  let initialState = defaultValue
  let previousState = null
  let state = model._config[field].modifier
    ? model._config[field].modifier(clone(initialState), previousState)
    : clone(initialState)
  let timer

  let aclosure = function (value, doProject) {
    if (arguments.length === 0) return clone(state)
    const stateChanged = !isequal(state, value)
    previousState = clone(state)
    state = model._config[field].modifier
      ? model._config[field].modifier(clone(value), previousState)
      : clone(value)

    let fieldProjector = model._config[field].projector
    if (fieldProjector && stateChanged && doProject !== false) {
      if (debounce) {
        clearTimeout(timer)
        timer = setTimeout(() => fieldProjector(value, model.data(), model), debounce)
      } else {
        fieldProjector(value, model.data(), model)
      }
    }

    if (!fieldProjector && projector && stateChanged && doProject !== false) {
      if (debounce) {
        clearTimeout(timer)
        timer = setTimeout(() => projector(model.data(), model), debounce)
      } else {
        projector(model.data(), model)
      }
    }
  }

  aclosure.isDirty = () => !isequal(initialState, state)

  aclosure.setAndValidate = (value) => {
    aclosure(value)
    aclosure.isValid()
  }

  aclosure.isValid = (attachError) => {
    let error, cleaner, value
    let config = model._config[field]
    cleaner = config.cleaner
    value = cleaner ? cleaner(aclosure()) : aclosure()

    let validator = isFunction(config) || isArray(config)
      ? config
      : config.validator

    error = validateSingle(value, validator, multipleErrors, model.data(), field)

    if (attachError !== false) {
      aclosure.error(error || undefined)
    }

    return error === undefined || (multipleErrors && error.length === 0)
  }

  aclosure.reset = (doProject) => {
    doProject === false ? aclosure(initialState, false) : aclosure(initialState)
    aclosure.error(undefined)
  }

  aclosure.error = (function () {
    let state
    return function (error) {
      if (arguments.length === 0) return state
      if (!multipleErrors && isArray(error) && error.length > 0) {
        state = error[0]
      } else {
        state = error
      }
    }
  }())

  aclosure.setInitialValue = function (value) {
    initialState = clone(value)
  }

  return aclosure
}

module.exports = function (config, multipleErrors = false, projector) {
  let formModel = {
    _config: config,
    isValid (attachError) {
      let truthPool = Object.keys(config).reduce((pool, key) => {
        pool.push(this[key].isValid(attachError))
        return pool
      }, [])

      return truthPool.every((value) => { return value === true })
    },

    isDirty () {
      return Object.keys(config).some((akey) => {
        return this[akey].isDirty()
      })
    },

    data (init, setAsInitialValue) {
      if (init) {
        Object.keys(init).forEach((key) => {
          let value = init[key]
          if (config[key]) {
            let field = this[key]
            field(value, false)
            setAsInitialValue && field.setInitialValue(value)
          }
        })

        projector && projector(this.data())
      } else {
        return Object.keys(config).reduce((data, key) => {
          let cleaner = config[key].cleaner
          let value = this[key]()
          data[key] = cleaner ? cleaner(value) : value
          return data
        }, {})
      }
    },

    setInitialValue (data) {
      Object.keys(config).forEach((key) => {
        this[key].setInitialValue(data[key])
      })
    },

    error (suppliedError) {
      if (arguments.length === 0) {
        return Object.keys(config).reduce((error, key) => {
          error[key] = this[key].error()
          return error
        }, {})
      } else {
        Object.keys(config).forEach((key) => {
          this[key].error(suppliedError[key] ? suppliedError[key] : undefined)
        })
      }
    },

    reset () {
      Object.keys(config).forEach((key) => {
        this[key].reset(false)
        this[key].error(undefined)
      })

      projector && projector(this.data())
    },

    getUpdates () {
      return Object.keys(config).reduce((updates, key) => {
        if (this[key].isDirty()) {
          let cleaner = config[key].cleaner
          let value = this[key]()

          updates[key] = cleaner ? cleaner(value) : value
        }
        return updates
      }, {})
    }
  }

  Object.keys(config).forEach((key) => {
    let field = config[key]
    if (!isValidValidator(field) && !isValidValidator(field.validator)) {
      throw Error(`'${key}' needs a validator.`)
    }
    formModel[key] = prop(formModel, key, field.default, multipleErrors, projector, field.debounce)
  })

  return formModel
}
