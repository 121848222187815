import m from 'mithril'
import Icon from '../../common/icon/icon'
import { getPlayersIntersection } from '../../../models/players'
import t from '../../../lib/translate'

const LogGamePlayers = ({ attrs }) => {
  const players = attrs.players
  const teams = attrs.teams

  const getPlayerNickname = id => {
    if (!id) return '-'
    const playerId = parseInt(id, 10)
    const player = players.find(player => player.id === playerId)
    return player
      ? player.nickname
      : '-'
  }

  const somePlayerSelected = () => getPlayersIntersection(players, teams).length > 0
  const allPlayersSelected = () => getPlayersIntersection(players, teams).length === 4
  const isSelected = playerId => [
    teams.redTeam.defence,
    teams.redTeam.attack,
    teams.blueTeam.defence,
    teams.blueTeam.attack
  ].includes(playerId)

  const selectButton = (playerId, team, position, viewportSize) => {
    const playerAtClickedPosition = teams[team][position]
    return m('button.button', {
      className: [
        playerAtClickedPosition === playerId ? team === 'redTeam' ? 'is-danger' : 'is-info' : 'is-light',
        viewportSize !== 'narrow' ? 'is-medium' : ''
      ].join(' '),
      type: 'button',
      onclick () {
        const otherTeam = team === 'redTeam' ? 'blueTeam' : 'redTeam'
        const otherPosition = position === 'attack' ? 'defence' : 'attack'
        if (playerAtClickedPosition && playerAtClickedPosition === playerId) {
          teams[team][position] = null
          return
        }
        if (teams[team][otherPosition] === playerId) teams[team][otherPosition] = null
        if (teams[otherTeam][position] === playerId) teams[otherTeam][position] = null
        if (teams[otherTeam][otherPosition] === playerId) teams[otherTeam][otherPosition] = null
        teams[team][position] = playerId
      }
    }, m('strong', position === 'attack' ? 'A' : 'D'))
  }

  const swapRedTeam = () => {
    [teams.redTeam.defence, teams.redTeam.attack] = [teams.redTeam.attack, teams.redTeam.defence]
  }
  const swapBlueTeam = () => {
    [teams.blueTeam.defence, teams.blueTeam.attack] = [teams.blueTeam.attack, teams.blueTeam.defence]
  }
  const swapTeams = () => {
    [
      teams.redTeam.defence,
      teams.redTeam.attack,
      teams.blueTeam.defence,
      teams.blueTeam.attack
    ] = [
      teams.blueTeam.defence,
      teams.blueTeam.attack,
      teams.redTeam.defence,
      teams.redTeam.attack
    ]
  }

  function view () {
    const viewport = attrs.app.state.viewport()
    return [
      m('table.table.is-borderless.narrow-container.new-game-table.vamid', [
        m('tr', [
          m('th.td-shrink.has-text-centered.has-text-danger.is-size-4', {
            colspan: 2
          }, [
            m(Icon, { name: 'faMale', classes: ['mr1'] }),
            m(Icon, { name: 'faMale' })
          ]),
          m('th.td-expand.has-text-centered', m('.is-lowercase.mt2', t('games.select_players'))),
          m('th.td-shrink.has-text-centered.has-text-info.is-size-4', {
            colspan: 2
          }, [
            m(Icon, { name: 'faMale', classes: ['mr1'] }),
            m(Icon, { name: 'faMale' })
          ])
        ]),
        players.map(player => m('tr', [
          m('td.has-text-centered', selectButton(player.id, 'redTeam', 'defence', viewport.size)),
          m('td.has-text-centered', selectButton(player.id, 'redTeam', 'attack', viewport.size)),
          m('td.has-text-centered', {
            className: isSelected(player.id) ? 'has-text-weight-bold is-light' : ''
          }, player.nickname),
          m('td.has-text-centered', selectButton(player.id, 'blueTeam', 'attack', viewport.size)),
          m('td.has-text-centered', selectButton(player.id, 'blueTeam', 'defence', viewport.size))
        ])),
        allPlayersSelected() && m('tr', [
          m('td.td-shrink', {
            colspan: 2
          }, [
            m('button.button.is-light.is-medium.mt3', {
              onclick: swapRedTeam,
              title: t('games.swap_positions'),
              type: 'button'
            }, [
              m(Icon, { name: 'faMale', classes: ['has-text-danger', 'mr1'] }),
              m(Icon, { name: 'faExchangeAlt' }),
              m(Icon, { name: 'faMale', classes: ['has-text-danger', 'ml1'] })
            ])
          ]),
          m('td.td-expand.has-text-centered', [
            m('button.button.is-light.is-medium.mt3', {
              onclick: swapTeams,
              title: t('games.swap_teams'),
              type: 'button'
            }, [
              m(Icon, { name: 'faMale', classes: ['has-text-danger'] }),
              m(Icon, { name: 'faMale', classes: ['has-text-danger', 'mr1'] }),
              m(Icon, { name: 'faExchangeAlt' }),
              m(Icon, { name: 'faMale', classes: ['has-text-info', 'ml1'] }),
              m(Icon, { name: 'faMale', classes: ['has-text-info'] })
            ])
          ]),
          m('td.td-shrink.has-text-right', {
            colspan: 2
          }, [
            m('button.button.is-light.is-medium.mt3', {
              onclick: swapBlueTeam,
              title: t('games.swap_positions'),
              type: 'button'
            }, [
              m(Icon, { name: 'faMale', classes: ['has-text-info', 'mr1'] }),
              m(Icon, { name: 'faExchangeAlt' }),
              m(Icon, { name: 'faMale', classes: ['has-text-info', 'ml1'] })
            ])
          ])
        ])
      ]),
      teams && somePlayerSelected() && m('.new-game-selected-players', [
        m('table.table.is-borderless.narrow-container.mhauto', [
          m('tr', [
            m('td.has-text-right.has-text-weight-semibold.w50', [
              // Position
              m('.is-size-7.is-pulled-left', 'D'),
              // Red team defence
              getPlayerNickname(teams.redTeam.defence),
              m(Icon, { name: 'faMale', classes: ['ml2', 'has-text-danger'] })
            ]),
            m('td.has-text-weight-semibold.w50', [
              // Position
              m('.is-size-7.is-pulled-right', 'D'),
              // Blue team attack
              m(Icon, { name: 'faMale', classes: ['mr2', 'has-text-info'] }),
              getPlayerNickname(teams.blueTeam.defence)
            ])
          ]),
          m('tr', [
            m('td.has-text-right.has-text-weight-semibold', [
              // Positions
              m('.is-size-7.is-pulled-left', 'A'),
              // Red team attack
              getPlayerNickname(teams.redTeam.attack),
              m(Icon, { name: 'faMale', classes: ['ml2', 'has-text-danger'] })
            ]),
            m('td.has-text-weight-semibold', [
              // Positions
              m('.is-size-7.is-pulled-right', 'A'),
              // Blue team attack
              m(Icon, { name: 'faMale', classes: ['mr2', 'has-text-info'] }),
              getPlayerNickname(teams.blueTeam.attack)
            ])
          ])
        ]),
        m('button.button.is-primary.is-large.w100.new-game-continue-button', {
          disabled: !allPlayersSelected(),
          onclick: attrs.submit
        }, t('common.continue'))
      ])
    ]
  }

  return {
    view
  }
}

export default LogGamePlayers
