import playersProfilePictures from '../../data/players-profile-pictures'

const formatTeam = team => {
  const games = team.wins.all + team.losses.all
  return {
    ...team,
    games,
    winsPercent: Math.round(team.wins.all * 100 / games),
    lossesPercent: Math.round(team.losses.all * 100 / games),
    winsDifference: team.wins.all - team.losses.all,
    skunksDifference: team.wins.skunks - team.losses.skunks,
    defenceProfilePicture: playersProfilePictures[team.defence.id], // TODO temp
    attackProfilePicture: playersProfilePictures[team.attack.id] // TODO temp
  }
}

export {
  formatTeam
}
