import m from 'mithril'

const ResponsiveSize = {
  view: ({ attrs }) => {
    const viewport = attrs.app.state.viewport()
    return m('.responsive-size', {
      style: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        padding: '.125rem .25rem',
        'background-color': '#333',
        color: 'white',
        'font-size': '.75rem'
      }
    }, [
      m('.hide.is-block-mobile', 'mobile (up to 768px)'),
      m('.hide.is-block-tablet-only', 'tablet (between 769px and 1087px)'),
      m('.hide.is-block-desktop-only', 'desktop (between 1088px and 1279px)'),
      m('.hide.is-block-widescreen-only', 'widescreen (between 1280px and 1471px)'),
      m('.hide.is-block-fullhd', 'fullhd (1472px and above)'),
      ` [${viewport.width}x${viewport.height}, ${viewport.size}]`
    ])
  }
}

export default ResponsiveSize
