import is from './is'
import t from './translate'

const validate = {
  required: value => value === undefined || value === null || value === '' ? t('forms.required_field') : null,
  email: value => validate.required(value) || !is.email(value) ? t('forms.invalid_email_address') : null,
  ip: value => validate.required(value) || !is.ip(value) ? t('forms.invalid_ip_address') : null,
  number: value => validate.required(value) || isNaN(value) ? t('forms.invalid_number') : null,
  numberBetween: (value, min, max) => validate.required(value) || isNaN(value) ? t('forms.invalid_number')
    : value < min || value > max
      ? t('forms.invalid_number_between', { min, max })
      : null,
  robustPassword: value => validate.required(value) || !is.robustPassword(value) ? t('common.password_strength_error') : null,
  confirmPassword: (password, repeatPassword) => password !== repeatPassword
    ? t('forms.invalid_confirm_password') : null,
  rename: (value, newValue) => validate.required(value) || value === newValue
    ? t('forms.invalid_rename') : null,
  username: value => !is.username(value) ? t('forms.invalid_username') : null,
  skip: () => null
}

module.exports = validate
