import format from 'date-fns/format'
import { pickRandomPlayer } from './players'
import { dateFormatHumanCompact, dateFormatHuman } from '../config'

const randomDate = (start, end) => new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))

const randomScore = () => Math.floor(Math.random() * 6)

const formatGame = game => ({
  ...game,
  humanDate: format(
    new Date(game.date),
    dateFormatHuman
  ),
  humanDateCompact: format(
    new Date(game.date),
    dateFormatHumanCompact
  ),
  winner: game.redTeam.score - game.blueTeam.score > 0 ? 'redTeam' : 'blueTeam',
  cappotto: Math.abs(game.redTeam.score - game.blueTeam.score) === 6
})

const formatNewGame = (teams, score, date) => ({
  ...date && { date },
  redTeam: {
    defence: {
      id: teams.redTeam.defence.id
    },
    attack: {
      id: teams.redTeam.attack.id
    },
    score: score.redTeam
  },
  blueTeam: {
    defence: {
      id: teams.blueTeam.defence.id
    },
    attack: {
      id: teams.blueTeam.attack.id
    },
    score: score.blueTeam
  }
})

const mockGames = players => new Array(100)
  .fill(null)
  .map(() => {
    const date = randomDate(new Date(2018, 0, 1), new Date())
    const player1 = pickRandomPlayer(players)
    const player2 = pickRandomPlayer(players, [player1])
    const player3 = pickRandomPlayer(players, [player1, player2])
    const player4 = pickRandomPlayer(players, [player1, player2, player3])
    const redScore = Math.random() >= 0.5 ? 6 : randomScore()
    const blueScore = redScore === 6 ? randomScore() : 6
    return formatGame({
      date,
      redTeam: {
        defence: player2,
        attack: player1,
        score: redScore
      },
      blueTeam: {
        defence: player4,
        attack: player3,
        score: blueScore
      }
    })
  })
  .sort((a, b) => b.date.getTime() - a.date.getTime())

export {
  formatGame,
  formatNewGame,
  mockGames
}
