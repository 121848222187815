import playersProfilePictures from '../../data/players-profile-pictures'

const formatPlayer = player => ({
  ...player,
  games: player.wins.all + player.losses.all,
  gamesDifference: player.wins.all - player.losses.all,
  skunksDifference: player.wins.skunks - player.losses.skunks,
  profilePicture: playersProfilePictures[player.id] // TODO temp
})

const formatUser = user => ({
  ...user,
  profilePicture: playersProfilePictures[user.id] // TODO temp
})

const pickRandomPlayer = (players, excludePlayers = []) => {
  const index = Math.floor(Math.random() * players.length)
  const randomPlayer = players[index]
  return excludePlayers.find(player => player.id === randomPlayer.id)
    ? pickRandomPlayer(players, excludePlayers)
    : randomPlayer
}

const filterPlayersByRecentActivity = (players, showRecentlyActiveOnly) => showRecentlyActiveOnly
  ? players
    .filter(player => player.latestGame)
    .sort((a, b) => b.latestGame.date.localeCompare(a.latestGame.date))
    .slice(0, 8)
    .sort((a, b) => a.nickname.localeCompare(b.nickname))
  : players

const getPlayersIntersection = (players = [], teams) => {
  const available = players.map(player => player.id)
  const selected = teams
    ? [teams.redTeam.defence, teams.redTeam.attack, teams.blueTeam.defence, teams.blueTeam.attack]
    : []
  return available.filter(id => selected.includes(id))
}

export {
  formatPlayer,
  formatUser,
  pickRandomPlayer,
  filterPlayersByRecentActivity,
  getPlayersIntersection
}
