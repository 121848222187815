import m from 'mithril'
import t from '../../../lib/translate'
import Icon from '../icon/icon'
import InputField from './input-field'

const PasswordField = ({ attrs }) => {
  let showPassword = false

  const togglePassword = e => {
    e.preventDefault()
    showPassword = !showPassword
  }

  function view () {
    return [
      m('a.is-pulled-right.is-lowercase', {
        href: '#',
        tabindex: '-1',
        onclick: togglePassword
      }, [
        m('span.mr1', showPassword ? t('common.hide') : t('common.show')),
        m(Icon, { name: showPassword ? 'faEyeSlash' : 'faEye' })
      ]),
      m(InputField, {
        field: attrs.field,
        id: attrs.id,
        label: attrs.label,
        type: showPassword ? 'text' : 'password',
        helper: attrs.helper
      })
    ]
  }

  return {
    view
  }
}

export default PasswordField
