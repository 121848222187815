// Detect viewport width
const getViewport = () => {
  const w = window
  const d = document
  const e = d.documentElement
  const g = d.getElementsByTagName('body')[0]
  const width = Math.max(w.innerWidth, e.clientWidth, g.clientWidth || 0)
  const height = Math.max(w.innerHeight, e.clientHeight, g.clientHeight || 0)
  const size = width < 400
    ? 'narrow'
    : width < 768
      ? 'mobile'
      : width < 1088
        ? 'tablet'
        : width < 1280
          ? 'desktop'
          : width < 1472
            ? 'widescreen'
            : 'fullhd'
  return ({ width, height, size })
}

export {
  getViewport
}
