import m from 'mithril'
import isDate from 'date-fns/isDate'
import flatpickr from 'flatpickr'
import LabelPlugin from 'flatpickr/dist/plugins/labelPlugin/labelPlugin'

const DatePickerField = ({ attrs }) => {
  // Check for required attrs
  if (!attrs.field) {
    throw new Error('InputField: missing required "field"')
  }
  if (typeof attrs.field !== 'function') {
    throw new Error('InputField: "field" must be a function')
  }
  if (!attrs.id) {
    throw new Error('InputField: missing required "id"')
  }

  let datepicker

  function view ({ attrs }) {
    return [
      // Label (optional)
      attrs.label && m('label.label', {
        for: attrs.id
      }, attrs.label),
      // DatePicker field
      m('input.input', {
        id: attrs.id,
        className: attrs.field.error() ? 'is-danger' : '',
        type: 'text',
        oncreate ({ dom }) {
          datepicker = flatpickr(dom, {
            altInput: true,
            altInputClass: 'input searchform-checkin-alt',
            altFormat: attrs.altFormat || 'j F Y',
            defaultDate: attrs.field()
              ? new Date(attrs.field())
              : [],
            disableMobile: !!attrs.disableMobile,
            enableTime: !!attrs.enableTime,
            time_24hr: true,
            minDate: '1970-01-01',
            plugins: [
              new LabelPlugin()
            ],
            onChange: selectedDates => {
              if (selectedDates && selectedDates[0]) {
                attrs.field(selectedDates[0])
                const el = document.querySelector('.searchform-checkin-alt')
                el.classList.remove('is-danger')
              }
            },
            onClose: () => {
              m.redraw()
            }
          })
          if (attrs.autoOpen) datepicker.open()
        },
        onremove () {
          if (datepicker) datepicker.destroy()
        },

        disabled: attrs.disabled, // optional
        readonly: attrs.readonly, // optional
        placeholder: attrs.placeholder, // optional
        tabindex: attrs.tabindex, // optional
        autofocus: attrs.autofocus // optional
      }),
      // Display error message, if any
      attrs.field.error() && m('p.help.is-danger', attrs.field.error()),
      // Helper message (optional)
      attrs.helper && m('p.help.has-text-grey', attrs.helper)
    ]
  }

  return { view }
}

export default DatePickerField
